// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

// TODO: color the "meets the SLA" and "does not meet the SLA" parts of the graph differently.
// Maybe a different fill color.
// See https://github.com/chartjs/Chart.js/issues/2430 for implementation

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import $ from 'jquery';
import _ from 'underscore';
import '../js/bootstrap_js_files.js';
import tippy from 'tippy.js';

import initializeGlobalAjaxRailsForm from '../js/initialize-global-ajax-rails_form.js';
import {destroyGlobalSelectizeInput, initializeGlobalSelectizeInput,} from '../js/initialize-global-selectize-input.js';
import {initializeCompaniesLiveReportMarketsTable} from "../js/companies/live_report";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
Chart.register(annotationPlugin);

// Prevent same-page anchor links from reloading the page
// From https://github.com/turbolinks/turbolinks/issues/75#issuecomment-445325162
document.addEventListener('turbolinks:click', (event) => {
  const anchorElement = event.target;
  const isSamePageAnchor =
    anchorElement.hash &&
    anchorElement.origin === window.location.origin &&
    anchorElement.pathname === window.location.pathname;

  if (!isSamePageAnchor) return;

  if (isSamePageAnchor) {
    event.preventDefault();

    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
      event.data.url,
      Turbolinks.uuid()
    );
  }
});

function initializeAdminMarketMakerExchangeReportedSnapshotsCreateForm() {
  // Return if this partial isn't in the DOM
  if (
    $('.v-admin-market-maker-exchange-reported-snapshots-create-form').length ==
    0
  ) {
    return;
  }

  tippy('.js-exchange-user-id-missing-tooltip', {
    content: 'Contact Brian if user id is missing',
    trigger: 'mouseenter',
  });
}

// Turbolink caches previous pages, which might cause scripts to re-evaluate when
// navigating back to it, so we need to destroy global initialize.
// ref: https://github.com/turbolinks/turbolinks/issues/106
document.addEventListener('turbolinks:before-cache', function () {
  destroyGlobalSelectizeInput();
  destroyToggleSection();
  destroyCompaniesLiveReportMarketsTable?.();
});

let destroyCompaniesLiveReportMarketsTable;

function destroyToggleSection() {
  $(document).off('click', '.js-toggle-section')
}

function initializeToggleSection() {
  $(document).on('click', '.js-toggle-section', function (event) {
    event.preventDefault();
    const $toggle = $(this);
    const section = $toggle.data('section');
    const toggleType = $toggle.data('type');
    const baseText = $toggle.text()
    const toggleText = $toggle.data('toggleText')
    if(toggleText) {
      $toggle.text(toggleText)
      $toggle.data('toggleText', baseText)
    }

    const $section = $(`.js-togglable-section[data-section=${section}`);
    if ($section.length > 0) {
      if (toggleType === 'instant') {
        $section.toggle(0);
      } else {
        $section.slideToggle();
      }
    }
  });
}

function initializeDataTable(tableId, opts) {
  new DataTable(tableId, opts);
}

function initializeCopyToClipboardLink() {
  const tooltipContentResetTimeout = 2000;
  const copyToClipboardTooltip = 'Copy to clipboard';
  const copiedToClipboardSuccessTooltip = 'Copied to clipboard!';

  // Instantiate title tooltips
  tippy('.js-copy-content-link', {
    content: copyToClipboardTooltip,
    trigger: 'mouseenter',
    hideOnClick: false,
  });

  $(document).on('click', '.js-copy-content-link', function (event) {
    const link = this;
    const $link = $(link);

    // Don't scrollto the element since we're copied the URL
    event.preventDefault();

    // Swap icon
    $link.find('.js-copy-icon').addClass('d-none');
    $link.find('.js-copied-icon').removeClass('d-none');

    // Copy content to the clipboard
    navigator.clipboard.writeText($link.data('content')).then(() => {
      // Then set success copy in tooltip
      link._tippy.setContent(copiedToClipboardSuccessTooltip);

      // Reset copy after a couple seconds
      setTimeout(function () {
        link._tippy.setContent(copyToClipboardTooltip);

        // Swap icon back
        $link.find('.js-copy-icon').removeClass('d-none');
        $link.find('.js-copied-icon').addClass('d-none');
      }, tooltipContentResetTimeout);
    });
  });
}

function initializeAdminMarketMakerServiceLevelAgreementsShow() {
  if ($('.v-admin-market-maker-service-level-agreements-show').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminMarketMakerServiceLevelAgreementIndex() {
  if ($('.admin-market-maker-service-level-agreement-index').length == 0) {
    return;
  }
  initializeToggleSection();
  initializeCopyToClipboardLink();
}

function initializeAdminMarketMakerServiceLevelAgreementNew() {
  if ($('.v-admin-market-maker-service-level-agreements-new').length == 0) {
    return;
  }

  const $previewButton = $('.js-preview-button')
  $previewButton.click((event) => {
    event.preventDefault();

    const companyName = $('.js-company-name-input').val()
    const assetSymbol = $('.js-asset-symbol-input').val()
    const snapshotsEnabled = $('.js-snapshots-enabled-input').is(':checked')
    const accelerateSnapshots = $('.js-accelerate-snapshots-input').is(':checked')
    const url = $previewButton.data('url') +
      '?company_name=' + companyName +
      '&asset_symbol=' + assetSymbol +
      '&snapshots_enabled=' + snapshotsEnabled +
      '&accelerate_snapshots=' + accelerateSnapshots

    window.location = url
    return false;
  })
}

function initializeAdminAssetPairConfigsNewFromApi() {
  if ($('.v-admin-asset-pair-configs-new-from-api').length == 0) {
    return;
  }

  const $viewAssetDataForm = $('.js-view-asset-data-form')
  $viewAssetDataForm.submit((event) => {
    event.preventDefault();

    const assetSymbol = $('.js-asset-symbol-input').val()
    const sufficientVolumeThreshold = $('.js-sufficient-volume-threshold-input').val()
    const url = $viewAssetDataForm.data('url') + '?asset_symbol=' + assetSymbol + '&sufficient_volume_threshold=' + sufficientVolumeThreshold

    window.location = url
    return false;
  })

  initializeToggleSection();
}


function initializeAdminAssetPairConfigsAssetSummary() {
  if ($('.v-admin-asset-pair-configs-asset-summary').length == 0) {
    return;
  }
}

function initializeAdminAssetPairConfigsShow() {
  if ($('.v-admin-asset-pair-configs-show').length == 0) {
    return;
  }

  initializeCopyToClipboardLink();
}

function initializeAdminExchangeConfigsCreateForm() {
  if ($('.v-admin-exchange-configs-create-form').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminExchangeConfigsUpdateForm() {
  if ($('.v-admin-exchange-configs-update-form').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminAssetConfigsCreateForm() {
  if ($('.v-admin-asset-configs-create-form').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminAssetConfigsUpdateForm() {
  if ($('.v-admin-asset-configs-update-form').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminAssetPairConfigsCreateForm() {
  if ($('.v-admin-asset-pair-configs-create-form').length == 0) {
    return;
  }
  initializeToggleSection();

  function autoGenerateHumanName(baseAsset, counterAsset) {
    const perpetualHumanName = 'PERP';
    const baseAssetValue = $('.js-asset-pair-config-base-asset').val();
    const counterAssetValue = $('.js-asset-pair-config-counter-asset').val();
    const perpetualFutureValue = $('.js-asset-pair-config-perpetual-future').is(
      ':checked'
    );
    const baseForHumanName = [];
    if (baseAssetValue) baseForHumanName.push(baseAssetValue);
    if (counterAssetValue) baseForHumanName.push(counterAssetValue);
    if (perpetualFutureValue) baseForHumanName.push(perpetualHumanName);

    $('.js-asset-pair-config-human-name').val(baseForHumanName.join('-'));
  }

  $(document).on(
    'input',
    '.js-asset-pair-config-base-asset',
    autoGenerateHumanName
  );
  $(document).on(
    'input',
    '.js-asset-pair-config-counter-asset',
    autoGenerateHumanName
  );
  $(document).on(
    'input',
    '.js-asset-pair-config-perpetual-future',
    autoGenerateHumanName
  );
}

function initializeMarketMakerSelfReportedSnapshotsIndex() {
  if ($('.v-market-maker-self-reported-snapshots-index').length == 0) {
    return;
  }
  initializeToggleSection();
}

// Write a function like the one above except for .market-maker-self-reported-snapshots-market-share
function initializeMarketMakerSelfReportedSnapshotsMarketShare() {
  if ($('.v-market-maker-self-reported-snapshots-market-share').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeCompaniesDerivativesReport() {
  if ($('.v-companies-derivatives-report').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeCompaniesServiceLevelAgreementsReport() {
  if ($('.v-companies-service-level-agreements-report').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminMarketMakerContractsShow () {
  if ($('.v-admin-market-maker-contracts-show').length == 0) {
    return;
  }
  initializeCopyToClipboardLink();
}

function initializeCompaniesLiquidityPoolsReport() {
  if ($('.v-companies-liquidity-pools-report').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeMarketMakersServiceLevelAgreementReport() {
  if ($('.v-market-makers-service-level-agreements-report').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeOrganizationSettings() {
  if ($('.v-organization-settings').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminWintermuteCsvIndex() {
  if ($('.v-admin-transform-wintermute-csv-index').length == 0) {
    return;
  }
  $('.js-submit-button').on('click', function(event) {
    $(event.currentTarget).addClass('btn-loading');
  })

  initializeToggleSection();
}

function initializeAdminIssuerCompaniesRecentSnapshots() {
  if ($('.v-admin-issuer-companies-recent-snapshots').length == 0) {
    return;
  }
  initializeToggleSection();
}

function initializeAdminMarketMakerServiceLevelAgreementsChangeExchangeAssetPairSymbolRequests() {
  if ($('.v-admin-market-maker-service-level-agreements-change-exchange-asset-pair-symbol-requests-index').length == 0) {
    return;
  }

  initializeCopyToClipboardLink();

  $('.js-accept-all').on('click', function(event) {
    $('.js-accept-radio').prop('checked', true);
  })

  $('.js-reject-all').on('click', function(event) {
    $('.js-reject-radio').prop('checked', true);
  })

  $('.js-do-nothing-all').on('click', function(event) {
    $('.js-do-nothing-radio').prop('checked', true);
  })
}

document.addEventListener('turbolinks:load', () => {
  // Initializers
  //
  // Recommendation: do not add an initializer for v-companies-summary-report
  // For better or worse, lots of pages are using that for shared styles.
  // If you add js for it, you'll affect lots of pages, perhaps causing conflicts
  // with each page's js.
  initializeGlobalAjaxRailsForm();
  initializeGlobalSelectizeInput();
  initializeAdminMarketMakerExchangeReportedSnapshotsCreateForm();

  initializeOrganizationSettings();

  initializeMarketMakerSelfReportedSnapshotsIndex();
  initializeMarketMakerSelfReportedSnapshotsMarketShare();

  initializeAdminAssetConfigsCreateForm();
  initializeAdminAssetConfigsUpdateForm();

  initializeAdminExchangeConfigsCreateForm();
  initializeAdminExchangeConfigsUpdateForm();

  initializeAdminAssetPairConfigsShow();
  initializeAdminAssetPairConfigsCreateForm();
  initializeAdminAssetPairConfigsNewFromApi();
  initializeAdminAssetPairConfigsAssetSummary();

  initializeAdminMarketMakerContractsShow();
  initializeAdminMarketMakerServiceLevelAgreementsShow();
  initializeAdminMarketMakerServiceLevelAgreementIndex();
  initializeAdminMarketMakerServiceLevelAgreementNew();

  initializeAdminMarketMakerServiceLevelAgreementsChangeExchangeAssetPairSymbolRequests();

  initializeCompaniesServiceLevelAgreementsReport();
  initializeCompaniesDerivativesReport();
  initializeCompaniesLiquidityPoolsReport();

  initializeMarketMakersServiceLevelAgreementReport();
  destroyCompaniesLiveReportMarketsTable = initializeCompaniesLiveReportMarketsTable();

  initializeAdminWintermuteCsvIndex();

  initializeAdminIssuerCompaniesRecentSnapshots();

  /** **************************************************
   // Tooltips
   /** **************************************************/
  const tooltipElements = document.querySelectorAll('.js-tooltip');

  // Initialize Tippy.js on those elements
  tooltipElements.forEach((element) => {
    tippy(element, {
      content: () => element.getAttribute('data-tooltip'),
      allowHTML: true,
      trigger: 'mouseenter',
      hideOnClick: false,
    });
  });

  /** **************************************************
    // Constants
    *****************************************************/
  const fontColor = '#979797';

  const maxRotation = 0;
  const xAxisMaxTicksLimit = 5;
  const yAxisMaxTicksLimit = 5;

  // TODO: better to read these constants in from a data attribute
  const CHART_DURATION_LAST_24_HOURS = 'chart_duration_last_24_hours';
  const CHART_DURATION_LAST_7_DAYS = 'chart_duration_last_7_days';
  const CHART_DURATION_LAST_30_DAYS = 'chart_duration_last_30_days';
  const CHART_DURATION_ALL = 'chart_duration_all';

  // Chart types
  const CHART_TYPE_LINE = 'line';
  const CHART_TYPE_BAR = 'bar';
  const CHART_TYPE_COMBO_BAR_LINE = 'combo-bar-line'

  // Formatting option
  const VALUE_FORMAT_RAW = 'raw';
  const VALUE_FORMAT_MONEY = 'money';
  const VALUE_FORMAT_PERCENT = 'percent';

  // Vertical axis IDs
  const LEFT_Y_AXIS_ID = 'y';
  const RIGHT_Y_AXIS_ID = 'y2';

  const aspectRatio = 2;

  const sharedChartLayoutProperties = {
    padding: {
      // TODO: no idea why this "negative top" is needed, but without it,
      // chartjs draws extra padding at the top of this chart
      top: -40,
      left: -2,
    },
  };

  const sharedDatasetProperties = {
    pointRadius: 0,
    pointHoverRadius: 2,
  };

  const sharedTooltipProperties = {
    interaction: {
      mode: 'nearest',
      axis: 'x',
    },
    padding: 12,
    boxPadding: 6,
    intersect: false,
    titleAlign: 'left',
    titleFont: {
      size: 13,
    },
    bodyFont: {
      size: 13,
    },
    bodyAlign: 'left',
    titleMarginBottom: 10,
    bodySpacing: 10,
    footerFont: {
      size: 13,
    },
    footerMarginTop: 10,
  };

  /** **************************************************
    // Helper functions
    *****************************************************/

  // use with .filter to check if every elements in the array are null
  function allAreNull(arr) {
    return arr.every((element) => element === null);
  }

  // From https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function datasetsFromChartDataHash(ctx, chartDataHash, chartType) {

    const datasets = [];
    for (const rawData of chartDataHash) {
      const datasetColor = rawData.line_color;
      const result = {
        ...{
          label: rawData.label,
          stack: rawData.stack,
          data: rawData.data,
          // type: rawData.type,
          borderColor: datasetColor,
          hidden: !rawData.display_by_default,
          labelTextColor: datasetColor,
          // stack: rawData.stack,
          borderDash: rawData.border_dash,

          yAxisID: rawData.chart_js_y_axis_id,
          valueFormat: rawData.chart_js_value_format,
          type: rawData.chart_js_type,
          order: rawData.chart_js_order,
        },
        ...sharedDatasetProperties,
      };

      // If dataset has a specific bar type, decorate it with that type
      if (result.type && result.type == CHART_TYPE_BAR) {
        decorateBarDatasets(result, datasetColor);

        // If dataset has a specific line type, decorate it with that type
      } else if (result.type && result.type == CHART_TYPE_LINE) {
        decorateLineDatasets(result, datasetColor, chartDataHash, ctx);

        // Else use the overall chartType
      } else if (chartType == CHART_TYPE_BAR) {
        decorateBarDatasets(result, datasetColor);
      } else if (chartType == CHART_TYPE_LINE) {
        decorateLineDatasets(result, datasetColor, chartDataHash, ctx);
      }

      datasets.push(result);
    }
    return datasets;
  }

  // Bar-specific dataset visual attributes
  function decorateBarDatasets(dataset, color) {
    dataset.backgroundColor = color;
    dataset.maxBarThickness = 50;
    return dataset;
  }

  // Line-specific dataset visual attributes
  function decorateLineDatasets(dataset, color, chartDataHash, ctx) {
    let backgroundColor = null;
    let fill = false;
    if (chartDataHash.length == 1) {
      // Go from y0 to y1
      // < y0 => color is rgbString
      // In between => gradient from rbgString to black
      // > y1 => color is background color
      const gradientFill = ctx.createLinearGradient(0, -1000, 0, 200);
      const rgbObject = hexToRgb(color);
      const rgbString = `rgba(${rgbObject.r}, ${rgbObject.g}, ${rgbObject.b}, 1)`;
      const pageBackgroundColor = '#14141B';

      gradientFill.addColorStop(0, rgbString);
      gradientFill.addColorStop(1, pageBackgroundColor);

      backgroundColor = gradientFill;
      fill = true;
    }

    dataset.fill = fill;
    dataset.backgroundColor = backgroundColor;

    return dataset;
  }

  function defaultDateOptionsForChartDuration(chartDuration) {
    let options;
    switch (chartDuration) {
      case CHART_DURATION_LAST_24_HOURS:
        // e.g. 3:29 PM
        options = { hour: 'numeric', minute: 'numeric' };
        break;
      case CHART_DURATION_LAST_7_DAYS:
        // e.g. Apr 13
        // options = {day: 'numeric', month: 'short'};
        options = { day: 'numeric', month: 'short' };

        break;
      case CHART_DURATION_LAST_30_DAYS:
        // e.g. Apr 13
        options = { day: 'numeric', month: 'short' };
        break;
      case CHART_DURATION_ALL:
        // e.g. TODO: should be Feb 2022
        // Need to send the year to the frontend. Will require parsing the label manually in the legend
        // Changing to Apr 13 so we can ship
        options = { day: 'numeric', month: 'short' };
        break;
    }
    return options;
  }

  function formatDateLabelForAxis(label, chartDuration) {
    const date = new Date(label);
    const options = defaultDateOptionsForChartDuration(chartDuration);
    const sharedOptions = { timeZone: 'utc', hour12: true };
    const mergedOptions = { ...options, ...sharedOptions };
    const formattedLabel = Intl.DateTimeFormat('en-US', mergedOptions).format(
      date
    );
    return formattedLabel;
  }

  function formatDateLabelForTooltip(
    tooltipItems,
    chartDuration,
    forceDateFormat,
    forceFullDateTimeFormat
  ) {
    const label = tooltipItems[0].label;
    const stack = tooltipItems[0].dataset.stack;

    const date = new Date(label);
    let options;

    const sharedOptions = { timeZone: 'utc', hour12: true };

    if (forceDateFormat) {
      options = { day: 'numeric', month: 'short' };
    } else if (forceFullDateTimeFormat) {
      options = {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
      };
    } else {
      options = defaultDateOptionsForChartDuration(chartDuration);
    }

    const mergedOptions = { ...options, ...sharedOptions };
    const formattedLabel = Intl.DateTimeFormat('en-US', mergedOptions).format(
      date
    );

    if (stack) {
      return formattedLabel + ' - ' + stack;
    } else {
      return formattedLabel;
    }
  }

  // Attach the duration toggle listeners
  // Note: this is used in the following files:
  //   - market_maker_self_reported_snapshots/_summary.html.haml
  //   - shared/charts/_group.html.haml
  // TODO: consider refactoring this so there isn't a dependency on exact CSS classes and DOM structure
  // Triggering an event on click might help.
  function setUpDurationToggleClickListeners($chartHolder) {
    // Attach the duration toggle listeners
    $chartHolder.find('.js-duration-toggle').click((event) => {
      // Prevent page jump
      event.preventDefault();

      const $durationToggle = $(event.target);
      // If the user clicked on the already-selected toggle, do nothing
      if ($durationToggle.hasClass('selected')) {
        return;
      }

      const chartToShowClass = $durationToggle.data('chartToShowClass');

      showChartForDuration($chartHolder, chartToShowClass);
    });
  }

  function showChartForDuration($chartHolder, chartToShowClass) {
    // Show the selected duration chart
    //
    // Note: it's important to show before we hide. If we hide before we show, then the screen will jump
    // if we're clicking on the bottom graph in the UI.

    // If the user clicked on an unselected toggle, show the chart for that toggle
    const $allDurationChartsForChartTypeHolder = $chartHolder.parents(
      '.js-all-duration-charts-for-chart-type-holder'
    );

    $allDurationChartsForChartTypeHolder
      .find(`.js-chart-holder.${chartToShowClass}`)
      .css({ minHeight: $chartHolder.height() })
      .removeClass('d-none');

    // Hide all other duration charts
    $allDurationChartsForChartTypeHolder
      .find(`.js-chart-holder:not(.${chartToShowClass})`)
      .addClass('d-none');
  }

  // When a checkbox is clicked, add or remove the chart layer (line) appropriately
  function setUpCheckboxClickListeners(chartInstance, $chartHolder) {
    $chartHolder.on('click', '.js-data-checkbox', function () {
      const $checkbox = $(this);

      // Get the datasetLabel
      const datasetLabel = $checkbox.data('datasetLabel');

      // Adjust the hidden property for all datasets whose label field matches the checkbox datasetLabel
      chartInstance.data.datasets.forEach(function (dataset) {
        if (dataset.label === datasetLabel) {
          dataset.hidden = !$checkbox.is(':checked');
        }
      });

      chartInstance.update();
    });
  }

  // Rounds two digits
  const roundNumber = function (value, decimals = 2) {
    const num = parseFloat(value);
    const roundedDigitsPow = Math.pow(10, decimals);
    const roundedNum =
      Math.round((num + Number.EPSILON) * roundedDigitsPow) / roundedDigitsPow;
    return roundedNum;
  };

  // Formatter - Money
  const formatMoney = function (value) {
    let minimumFractionDigits;
    let maximumSignificantDigits;
    let minimumSignificantDigits;

    if (value > 10) {
      minimumFractionDigits = 0
    } else if (value >= 1) {
      // $6.1 becomes $6.10
      // $1 becomes $1.00
      minimumFractionDigits = 2
    } else {
      // $0.04771 beocmes $0.0477
      minimumFractionDigits = 2
      minimumSignificantDigits = 2
      maximumSignificantDigits = 3
    }

    const formatterOptions = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minimumFractionDigits, // (causes 2500.99 to be printed as $2,501)
      minimumSignificantDigits: minimumSignificantDigits,
      maximumSignificantDigits: maximumSignificantDigits,
    };

    // Compact large numbers
    if (value > 1000) {
      formatterOptions['notation'] = 'compact';
    }

    const formatter = new Intl.NumberFormat('en-US', formatterOptions);
    return formatter.format(value);
  };

  // Formatter - Percent
  const formatPercent = function (value) {
    // Chartjs sometimes sends string as value instead of number
    const parsedValue = parseFloat(value);

    const precision = 2;
    const valueWithPrecision = parsedValue.toPrecision(precision);

    // Strip trailing zeroes and return
    return `${parseFloat(valueWithPrecision)}%`;
  };

  // Formatter - Raw
  const formatFixedRawValue = function (value) {
    // Chartjs sometimes sends string as value instead of number
    const parsedValue = parseFloat(value);

    const precision = 4;
    const valueWithPrecision = parsedValue.toPrecision(precision);

    // Strip trailing zeroes and return
    return parseFloat(valueWithPrecision);
  };

  // valueFormat: 'money' | 'raw' | 'percent'
  function getValueFormatter(valueFormat) {
    // Options - Pick formatter based on valueFormat data attribute
    let valueFormatter;

    if (valueFormat == VALUE_FORMAT_MONEY) {
      valueFormatter = formatMoney;
    } else if (valueFormat == VALUE_FORMAT_PERCENT) {
      valueFormatter = formatPercent;
    } else if (valueFormat == VALUE_FORMAT_RAW) {
      valueFormatter = formatFixedRawValue;
    }

    return valueFormatter;
  }

  // Function to check if there are overlaps in the dataset timestamps
  // timestampsArray is an array of strings
  function checkIfDateOverlap(timestampsArray) {
    const dates = _.map(timestampsArray, (timestamp) => {
      const date = new Date(timestamp);
      return `${date.getFullYear()}-${date.getUTCMonth()}-${date.getDate()}`;
    });
    return dates.length != _.uniq(dates).length;
  }

  // We assume that if timestamps are all midnight, we are dealing with dates
  function checkIfTimestampsAreDates(timestampsArray) {
    // .every breaks the loop when false is returned
    return timestampsArray.every((timestamp) => {
      const date = new Date(timestamp);

      // is midnight UTC
      return (
        date.getUTCHours() == 0 &&
        date.getUTCMinutes() == 0 &&
        date.getUTCSeconds() == 0
      );
    });
  }

  // Tooltip Function to render the sum of the data displayed
  function renderTooltipFooterText(tooltipItems, valueFormatter) {
    // No need to show footer if only one dataset is visible
    if (tooltipItems.length == 1) {
      return;
    }

    // Calculate total of current data displayed
    let total = 0;
    tooltipItems.map((tooltipItem) => {
      const value = tooltipItem.parsed.y;
      total += value;
    });

    // Display total
    return 'Total: ' + valueFormatter(total.toFixed(6));
  }

  // Tooltip Function to render the label text
  function renderTooltipLabelText(
    context,
    valueFormatter,
    showTooltipLabelPercentage
  ) {
    // Sometimes y is undefined, in which case don't render a label
    if (_.isUndefined(context.parsed.y) || _.isNull(context.parsed.y)) {
      return null;
    }

    // Get/parse the value
    const value = parseFloat(context.parsed.y);

    // Basic Label text format: "Price: $24"
    const labelText = context.dataset.label + ': ' + valueFormatter(value);

    // Percentage enabled
    if (showTooltipLabelPercentage) {
      // Find the datasets currently displayed
      const visibleDatasets = context.chart.data.datasets.filter(
        (dataset) => dataset.data.length
      );

      // If only one, donreturn basic label text
      if (visibleDatasets.length == 1) {
        return labelText;
      }

      // Compute total of data displayed
      let total = 0;
      context.chart.data.datasets.map((dataset) => {
        if (dataset.data[context.dataIndex]) {
          total += parseFloat(dataset.data[context.dataIndex]);
        }
      });

      // Add percent to the label text
      const percent = Math.floor((value / total) * 100 + 0.5);
      return labelText + ' - ' + formatPercent(percent);
    } else {
      return labelText;
    }
  }

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('.v-shared-charts-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.classList.add("v-shared-charts-tooltip")
      const table = document.createElement('table');
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  // Based on https://www.chartjs.org/docs/latest/samples/tooltip/html.html
  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(b => b.lines);
      const footerLines = tooltip.footer
      const tableHead = document.createElement('thead');

      titleLines.forEach(title => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const th = document.createElement('th');
        th.style.borderWidth = 0;
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        // Skip dataset with no data
        if(body.length === 0) return;

        const colors = tooltip.labelColors[i];
        const span = document.createElement('span');
        span.classList.add('shared-charts-tooltip-square')
        span.style.background = colors.borderColor;
        span.style.borderColor = colors.borderColor;
        const tr = document.createElement('tr');
        const td = document.createElement('td');
        const text = document.createTextNode(body);
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableFoot = document.createElement('tfoot');
      footerLines.forEach(footer => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const th = document.createElement('td');
        th.style.borderWidth = 0;
        const text = document.createTextNode(footer);

        th.appendChild(text);
        tr.appendChild(th);
        tableFoot.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
      tableRoot.appendChild(tableFoot);

    }

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  /** **************************************************
    // Generic function to create a bar chart
     *****************************************************/

  function createStandardChart(chartCanvasElement) {
    // Container and canva context
    const $chartHolder = $(chartCanvasElement).parents('.js-chart-holder');
    const ctx = chartCanvasElement.getContext('2d');

    // DOM Data attributes
    const dataset = ctx.canvas.dataset;

    // Chart data
    const chartType = dataset.chartType;
    const valueFormat = dataset.valueFormat;
    const labels = JSON.parse(dataset.labels);
    const yAnnotationValues = JSON.parse(dataset.yAnnotationValues);
    const y2AnnotationValues = JSON.parse(dataset.y2AnnotationValues);
    const y2AnnotationValues2 = JSON.parse(dataset.y2AnnotationValues2);

    const chartJsYAxisMinValue = dataset.chartJsYAxisMinValue;
    const chartJsY2AxisMinValue = dataset.chartJsY2AxisMinValue;
    const chartJsYAxisMaxValue = dataset.chartJsYAxisMaxValue;
    const chartJsY2AxisMaxValue = dataset.chartJsY2AxisMaxValue;

    const chartJsYAxisSuggestedMinValue = dataset.chartJsYAxisSuggestedMinValue;
    const chartJsY2AxisSuggestedMinValue =
      dataset.chartJsY2AxisSuggestedMinValue;
    const chartJsYAxisSuggestedMaxValue = dataset.chartJsYAxisSuggestedMaxValue;
    const chartJsY2AxisSuggestedMaxValue =
      dataset.chartJsY2AxisSuggestedMaxValue;

    const chartDuration = dataset.chartDuration;
    const chartDataHash = JSON.parse(dataset.chartDataHash);
    const showTooltipTotal = dataset.showTooltipTotal === 'true';
    let showTooltipLabelPercentage =
      dataset.showTooltipLabelPercentage === 'true';

    // Options - Build datasets based on given chart type
    let datasets = datasetsFromChartDataHash(ctx, chartDataHash, chartType);
    let y2AxisDatasets = datasets.filter((d) => d.yAxisID == RIGHT_Y_AXIS_ID);

    // Options - Pick formatter based on valueFormat data attribute
    let defaultValueFormatter = getValueFormatter(valueFormat);
    let yAxisValueFormatter = defaultValueFormatter;
    let y2AxisValueFormatter =
      y2AxisDatasets.length > 0
        ? getValueFormatter(y2AxisDatasets[0].valueFormat)
        : defaultValueFormatter;

    let yAxisMinValue = chartJsYAxisMinValue ? chartJsYAxisMinValue : null;
    let y2AxisMinValue = chartJsY2AxisMinValue ? chartJsY2AxisMinValue : null;
    let yAxisMaxValue = chartJsYAxisMaxValue ? chartJsYAxisMaxValue : null;
    let y2AxisMaxValue = chartJsY2AxisMaxValue ? chartJsY2AxisMaxValue : null;

    let yAxisSuggestedMinValue = chartJsYAxisSuggestedMinValue
      ? chartJsYAxisSuggestedMinValue
      : null;
    let y2AxisSuggestedMinValue = chartJsY2AxisSuggestedMinValue
      ? chartJsY2AxisSuggestedMinValue
      : null;
    let yAxisSuggestedMaxValue = chartJsYAxisSuggestedMaxValue
      ? chartJsYAxisSuggestedMaxValue
      : null;
    let y2AxisSuggestedMaxValue = chartJsY2AxisSuggestedMaxValue
      ? chartJsY2AxisSuggestedMaxValue
      : null;

    // Options - stacked if bar chart
    const isBarChart = chartType == CHART_TYPE_BAR || chartType === CHART_TYPE_COMBO_BAR_LINE;

    const isGroupedBarChart =
      isBarChart && !allAreNull(datasets.map((a) => a.stack));

    // Date format helpers
    const forceDateFormat = checkIfTimestampsAreDates(labels);
    const forceFullDateTimeFormat = checkIfDateOverlap(labels);

    // Don't display percentage if only one datasets
    if (datasets.length == 1) {
      showTooltipLabelPercentage = false;
    }

    // Used later for detemining whether we should shw the y2 axis
    const datasetsyAxisIDs = datasets.map((d) => d.yAxisID);

    const data = {
      labels: labels,
      datasets: datasets,
    };

    // Annotations = Dashed lines on the chart, using the y2AnnotationValues plugin
    let yAnnotationsObject = yAnnotationValues.map((value) => {
      return {
        type: 'line',
        borderColor: 'rgba(255,255,255,0.5)',
        borderWidth: 1,
        borderDash: [3, 6],
        scaleID: 'y',
        value: value,
      };
    });

    let y2AnnotationsObject = y2AnnotationValues.map((value) => {
      return {
        type: 'line',
        borderColor: 'red',
        borderWidth: 1,
        borderDash: [3, 6],
        scaleID: 'y2',
        value: value,
      };
    });

    // TODO: this is a hack. We should allow the backend to pass in an arbitrary
    // number of annotations
    let y2AnnotationsObject2 = y2AnnotationValues2.map((value) => {
      return {
        type: 'line',
        borderColor: '#22C7A4',
        borderWidth: 2,
        borderDash: [3, 6],
        scaleID: 'y2',
        value: value,
      };
    });

    const options = {
      layout: sharedChartLayoutProperties,
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: aspectRatio,
      // spanGaps keeps the line chart connected even if there's a gap
      // (due to missing data)
      spanGaps: true,

      // Bar chart: Don't display empty bars (data = null)
      skipNull: true,
      plugins: {
        legend: { display: false },
        annotation: {
          annotations: [
            ...yAnnotationsObject,
            ...y2AnnotationsObject,
            ...y2AnnotationsObject2,
          ],
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler,
          itemSort: function (a, b) {
            return b.datasetIndex - a.datasetIndex;
          },
          callbacks: {
            footer: function (tooltipItems) {
              if (showTooltipTotal) {
                return renderTooltipFooterText(
                  tooltipItems,
                  defaultValueFormatter
                );
              }
            },
            title: function (tooltipItems) {
              return formatDateLabelForTooltip(
                tooltipItems,
                chartDuration,
                forceDateFormat,
                forceFullDateTimeFormat
              );
            },
            label: function (context) {
              let valueFormatter = defaultValueFormatter;

              // dataset override the formatting options
              if (context.dataset.valueFormat) {
                valueFormatter = getValueFormatter(context.dataset.valueFormat);
              }

              return renderTooltipLabelText(
                context,
                valueFormatter,
                showTooltipLabelPercentage
              );
            },
            labelColor: function (context) {
              return {
                borderColor: context.dataset.borderColor,
                backgroundColor: 'black',
                borderWidth: 2,
                borderRadius: 0,
                fill: false,
              };
            },
          },
          ...sharedTooltipProperties,
        },
      },
      scales: {
        y: {
          id: LEFT_Y_AXIS_ID,
          min: yAxisMinValue,
          max: yAxisMaxValue,
          suggestedMax: yAxisSuggestedMaxValue,
          suggestedMin: yAxisSuggestedMinValue,
          // max: yAxisMaxValue,
          ticks: {
            color: fontColor,
            maxTicksLimit: yAxisMaxTicksLimit,
            callback: yAxisValueFormatter,
          },
          grid: {
            display: false,
          },
          stacked: isBarChart,
        },
        y2: {
          ID: RIGHT_Y_AXIS_ID,
          min: y2AxisMinValue,
          max: y2AxisMaxValue,
          suggestedMax: y2AxisSuggestedMaxValue,
          suggestedMin: y2AxisSuggestedMinValue,
          ticks: {
            color: fontColor,
            maxTicksLimit: yAxisMaxTicksLimit,
            callback: y2AxisValueFormatter,
          },
          grid: {
            display: false,
          },
          stacked: isBarChart,
          display: datasetsyAxisIDs.includes(RIGHT_Y_AXIS_ID),
          position: 'right',
        },
        x: {
          ticks: {
            maxRotation: maxRotation,
            maxTicksLimit: xAxisMaxTicksLimit,
            color: fontColor,
            callback: function (value, index, ticks) {
              const label = this.getLabelForValue(value);
              return formatDateLabelForAxis(label, chartDuration, false, false);
            },
          },
          grid: {
            display: false,
          },
          stacked: isBarChart,
        },
      },
    };

    // Note: this is O(N)
    function lastDatasetWithDataIndexForChartAndStack(chart, stack) {
      let lastDatasetWithDataIndex;

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        // If this dataset isn't for the input stack, skip it
        if (dataset.stack != stack) {
          return;
        }

        const datasetIsEmpty = allAreNull(dataset.data);

        if (chart.isDatasetVisible(datasetIndex) && !datasetIsEmpty) {
          lastDatasetWithDataIndex = datasetIndex;
        }
      });

      return lastDatasetWithDataIndex;
    }

    // Note: this is O(N)
    function isCurrentDatasetLastDatasetWithData(ctx) {
      const currentStack = ctx.dataset.stack;

      const lastDatasetWithDataIndex = lastDatasetWithDataIndexForChartAndStack(
        ctx.chart,
        currentStack
      );
      return ctx.datasetIndex == lastDatasetWithDataIndex;
    }

    // Note: this is O(N)
    function isStackTotalNonzero(ctx) {

      // Get all datasets matching the current stack
      const datasetsWithinStack = ctx.chart.data.datasets.filter((d) => d.stack === ctx.dataset.stack)

      // Get an array of all data
      const allData = datasetsWithinStack.map((d) => d.data).flat()

      // Using Array.some (rails equivalent of .any?) to return true as soon as:
      const _isStackTotalNonzero = allData.some((value) => {

        // a value is defined & a positive number
        return (!_.isUndefined(value) && parseFloat(value) > 0)
      })

      return _isStackTotalNonzero
    }

    // ChartJS plugins
    // Only "grouped" bar chart requires a top label
    let plugins = [];

    if (isGroupedBarChart) {
      // Add the plugins
      plugins = [ChartDataLabels];

      // ChartDataLabels Plugin options
      // https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html
      options.plugins.datalabels = {
        // UI
        align: 'top',
        anchor: 'end',
        textAlign: 'center',
        color: fontColor,

        // Formatter
        formatter: (value, ctx) => {
          return ctx.dataset.stack;
        },

        // Display label only if it's the last dataset (the one at the top of the stack visually)
        // and the stack total value is nonzero
        display: function (ctx) {
          // Note: display is presumably called for every dataset. The functions below loop over
          // every dataset. So, in total, display is O(N^2). I think this is ok since we don't have that
          // many datasets, but something to keep an eye on.
          return (
            isCurrentDatasetLastDatasetWithData(ctx) && isStackTotalNonzero(ctx)
          );
        },
      };
    }

    // Magic
    const chartInstance = new Chart(ctx, {
      type: chartType,
      data: data,
      options: options,
      plugins: plugins,
    });

    setUpCheckboxClickListeners(chartInstance, $chartHolder);

    setUpDurationToggleClickListeners($chartHolder);
  }

  /** **************************************************
    // Set up Charts
    *****************************************************/
  const charts = $('.js-chart');
  for (const chart of charts) {
    createStandardChart(chart);
  }

  /** **************************************************
     // Set up Market Maker Self Reported Summary table
    *****************************************************/
  const $chartHolder = $('.js-chart-holder.js-summary-table-chart-holder');
  setUpDurationToggleClickListeners($chartHolder);

  /**********************************************************
  // KPIs table
  **********************************************************/
  $('.js-show-slas-link').on('click', (event) => {
    $('.js-slas-visible-content').removeClass('d-none').show();
    $('.js-slas-hidden-content').hide();
    $('.js-market-maker-table').addClass('js-slas-visible');
    $('.v-companies-service-level-agreements-report .js-main-content-holder').addClass('js-slas-visible');
  });

  $('.js-hide-slas-link').on('click', () => {
    $('.js-slas-visible-content').hide();
    $('.js-slas-hidden-content').show();
    $('.js-market-maker-table').removeClass('js-slas-visible');
    $('.v-companies-service-level-agreements-report .js-main-content-holder').removeClass('js-slas-visible');
  });

  /** *******************************************************
  // Set up sidebar link scrolling
  **********************************************************/
  const $sidebarLinks = $('.js-sidebar-links');
  const $showSidebarLinksButton = $('.js-show-sidebar-links-button');

  $('.js-sidebar-anchor-link').click((event) => {
    // If $showSidebarLinksButton is visible, that means this is a mobile UI, and the
    // links should hide when we click a section
    if ($showSidebarLinksButton.is(':visible')) {
      // Prevent the usual anchor scroll
      event.preventDefault();

      // Get the target
      let anchorId = $(event.target).attr('href');

      // Slide the mobile navigation up then
      $sidebarLinks.slideUp({
        complete: () => {
          // update url with hash, which triggers a natural scroll to that sectio
          window.location.hash = anchorId;
        },
      });
    }
  });

  /** *******************************************************
    // Mobile menu
    **********************************************************/
  $showSidebarLinksButton.click(() => {
    if ($sidebarLinks.is(':visible')) {
      $sidebarLinks.slideUp();
    } else {
      $sidebarLinks.removeClass('d-none').slideDown();
    }
  });

  /** *******************************************************
    // Chart Header - Copy link to a particular chart
    **********************************************************/

  // UI text and settings
  const copyToClipboardTooltip = 'Copy link to this chart';
  const copiedToClipboardSuccessTooltip = 'Copied to clipboard!';
  const tooltipContentResetTimeout = 2000;

  // Instantiate title tooltips
  tippy('.js-chart-header-anchor-link', {
    content: copyToClipboardTooltip,
    trigger: 'mouseenter',
    hideOnClick: false,
  });

  // On icon click
  $('.js-chart-header-anchor-link').on('click', function (event) {
    // DOM element
    let link = this;
    let $link = $(this);

    // Don't scrollto the element since we're copied the URL
    event.preventDefault();

    // Swap icon
    $link.find('.js-copy-icon').addClass('d-none');
    $link.find('.js-copied-icon').removeClass('d-none');

    // Copy content to the clipboard
    navigator.clipboard.writeText(link.getAttribute('href')).then(() => {
      // Then set success copy in tooltip
      link._tippy.setContent(copiedToClipboardSuccessTooltip);

      // Reset copy after a couple seconds
      setTimeout(function () {
        link._tippy.setContent(copyToClipboardTooltip);

        // Swap icon back
        $link.find('.js-copy-icon').removeClass('d-none');
        $link.find('.js-copied-icon').addClass('d-none');
      }, tooltipContentResetTimeout);
    });
  });

  // Anchor link from the URL
  let $element = $(window.location.hash);

  // If actually linking to an element
  if ($element.length) {
    // and has a chartDuration data attribute
    let chartDuration = $element.data('chartDuration');
    let $chartDurationToggle = $element.find(
      `.js-duration-toggle[data-chart-to-show-class='${chartDuration}']`
    );

    // Show that chart
    if ($chartDurationToggle.length) {
      showChartForDuration($element, $element.data('chartDuration'));
    }

    // For browser to always scroll to element once
    $element[0].scrollIntoView();
  }
});
